import * as client_hooks from '../../../src/hooks.client.ts';


export { matchers } from './matchers.js';

export const nodes = [
	() => import('./nodes/0'),
	() => import('./nodes/1'),
	() => import('./nodes/2'),
	() => import('./nodes/3'),
	() => import('./nodes/4'),
	() => import('./nodes/5'),
	() => import('./nodes/6'),
	() => import('./nodes/7'),
	() => import('./nodes/8'),
	() => import('./nodes/9'),
	() => import('./nodes/10'),
	() => import('./nodes/11'),
	() => import('./nodes/12'),
	() => import('./nodes/13'),
	() => import('./nodes/14'),
	() => import('./nodes/15'),
	() => import('./nodes/16'),
	() => import('./nodes/17'),
	() => import('./nodes/18'),
	() => import('./nodes/19'),
	() => import('./nodes/20'),
	() => import('./nodes/21'),
	() => import('./nodes/22'),
	() => import('./nodes/23'),
	() => import('./nodes/24'),
	() => import('./nodes/25'),
	() => import('./nodes/26'),
	() => import('./nodes/27'),
	() => import('./nodes/28'),
	() => import('./nodes/29'),
	() => import('./nodes/30'),
	() => import('./nodes/31'),
	() => import('./nodes/32'),
	() => import('./nodes/33'),
	() => import('./nodes/34'),
	() => import('./nodes/35'),
	() => import('./nodes/36'),
	() => import('./nodes/37'),
	() => import('./nodes/38'),
	() => import('./nodes/39'),
	() => import('./nodes/40'),
	() => import('./nodes/41'),
	() => import('./nodes/42'),
	() => import('./nodes/43'),
	() => import('./nodes/44'),
	() => import('./nodes/45'),
	() => import('./nodes/46'),
	() => import('./nodes/47'),
	() => import('./nodes/48'),
	() => import('./nodes/49'),
	() => import('./nodes/50'),
	() => import('./nodes/51'),
	() => import('./nodes/52'),
	() => import('./nodes/53'),
	() => import('./nodes/54'),
	() => import('./nodes/55'),
	() => import('./nodes/56'),
	() => import('./nodes/57'),
	() => import('./nodes/58'),
	() => import('./nodes/59'),
	() => import('./nodes/60'),
	() => import('./nodes/61'),
	() => import('./nodes/62'),
	() => import('./nodes/63'),
	() => import('./nodes/64'),
	() => import('./nodes/65'),
	() => import('./nodes/66'),
	() => import('./nodes/67'),
	() => import('./nodes/68'),
	() => import('./nodes/69')
];

export const server_loads = [0,11,16,2,3];

export const dictionary = {
		"/": [~18],
		"/about-dubclub": [22],
		"/auth/complete/account": [23,[4]],
		"/auth/complete/social": [~24,[4]],
		"/auth/login": [25,[4]],
		"/auth/login/phone": [26,[4]],
		"/auth/verify": [27,[4]],
		"/auth/verify/phone": [28,[4]],
		"/capper/affiliates/links": [29,[5]],
		"/capper/affiliates/members": [30,[5]],
		"/capper/affiliates/sales": [31,[5]],
		"/capper/dashboard": [32],
		"/capper/discord/custom-bot/manage": [33,[6]],
		"/capper/discord/custom-bot/setup": [34,[6]],
		"/capper/discord/manage": [~35],
		"/capper/discord/setup": [~36],
		"/capper/integrations": [~37],
		"/capper/manage-prices": [~38,[7],[8]],
		"/capper/products": [~39],
		"/capper/profile/[name_or_price]": [~40],
		"/capper/publish": [~41],
		"/capper/referral": [42,[9]],
		"/capper/stream/[name]": [~43,[10]],
		"/checkout/[trackinterest_id]": [~44,[11]],
		"/footer": [45],
		"/plays/[textpickKey]": [~46],
		"/plays/[textpickKey]/[viewerKey]": [~47],
		"/privacy": [48],
		"/product/new": [49],
		"/publish/play/[capperKey]": [~50,[12]],
		"/publish/sales": [~51,[13]],
		"/refund-policy": [60],
		"/r/capper": [52],
		"/r/connected-accounts": [~53],
		"/r/discover-cappers": [54,[14]],
		"/r/discover-cappers/(discovery)/cappers": [55,[14,15]],
		"/r/discover-cappers/(discovery)/leagues": [56,[14,15]],
		"/r/discover-cappers/(discovery)/plays": [57,[14,15]],
		"/r/discover-cappers/(discovery)/search": [58,[14,15]],
		"/r/discover-cappers/(discovery)/sports": [59,[14,15]],
		"/samples/[sampleKey]": [~61],
		"/samples/[sampleKey]/[viewerKey]": [~62],
		"/terms-of-service": [68],
		"/t/engagement/[capperKey]": [~67],
		"/t/[productKey]": [63,[16]],
		"/t/[productKey]/edit": [64],
		"/t/[productKey]/faqs": [65,[16]],
		"/t/[productKey]/notifications": [66,[16]],
		"/viewm/[marketing=lookup_key]/[user=lookup_key]": [~69,[17]],
		"/[profileName]": [~19,[2]],
		"/[profileName]/edit": [~20,[2,3]],
		"/[profileName]/edit/sections": [~21,[2,3]]
	};

export const hooks = {
	handleError: client_hooks.handleError || (({ error }) => { console.error(error) }),

	reroute: (() => {})
};

export { default as root } from '../root.js';